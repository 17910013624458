.pageContainer {
  margin: 2rem auto;
}
.pageIntroductoryText {
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding-top: 8rem;
}

.pageIntroductoryText span {
  font-weight: 3.5rem;
}
.landingPage {
  background-image: url('./img/sarnoff-bg-2.jpg');
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15%;
}
.hrHolderTop {
  width: 30%;
  margin: 2em auto;
  height: 0.3rem;
  background: #fff;
}

.addressHRBottom {
  width: 77%;
  border-width: 0.3rem;
  /* margin: 2.5rem auto; */
  border: 1;
  opacity: 1;
}

.eventInfo {
  margin-top: 7rem;
}
/* .soldOut {
  background-color: red;
  color: black;
  display: inline-block;
  padding: 3px 10px;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 5px;
} */
.ieeeTVBtnDiv {
  margin-top: 4rem;
}

.ieeeTVBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: solid 2px #fff;
  padding: 20px;
}

.aboutNameStart::first-letter {
  font-size: 50px;
  color: #0a332b;
  font-weight: bold;
}
.aboutText {
  padding: 100px 0;
  font-size: 18px;
  letter-spacing: 1.1px;
  line-height: 1.5;
  font-weight: 500;
  /* opacity: 0.7; */
  color: #36454f;
}

.sponsors {
  padding-bottom: 5rem;
  text-align: center;
  background-color: #d8ddde;
}
.sponsorsImg {
  border-radius: 5px;
}
.sponsorsHr {
  align-items: center;
  border-width: 0.3rem;
  opacity: 1;
  margin: 0;
}
.eventSpeakers {
  background: #0a332b;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 6rem;
  padding: 4rem;
}
.speakerCard {
  /* padding: '4%';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  border-radius: 50%;
  overflow: hidden; */
  /* padding: 30px; */
}
.speakersTitle {
  text-align: center;
  /* text-decoration: underline; */
  color: #fff;
  text-decoration-color: #fff;
  /* padding-bottom: 6rem; */
  /* opacity: 0.9; */
  font-weight: 900;
}
/* .speaker {
} */
.speakerImg {
  border-radius: 100px;
  width: 12rem;
  max-width: 12rem;
  max-height: 12rem;
  /* width: 180px;
  height: 180px; */
  font-size: 13px;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}

.venueAndAccomodationBg {
  background-image: url('./img/5g.jpeg');
  height: 1000px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 6rem;
}

.venueAndAccomodationHeading {
  font-size: 3rem;
  padding-top: 3rem;
  text-align: center;
  /* text-decoration: underline; */
  color: #0a332b;
  /* text-decoration-color: #fff; */
}
.maps {
  text-align: center;
  align-self: center;
  padding: 3rem;
}
.venueAndAccomodationHotel {
  margin-top: 6rem;
  color: #fff;
  font-weight: 900;
  height: 900px;
}
.accomodationCol {
  padding: 16px;
}
.hotelH3 {
  color: #0a332b;
}
.hotelsPlus {
  padding-bottom: 10rem;
  background-color: #d8ddde;
  font-weight: 500;
}
.hotelOption {
  text-decoration: none !important;
}
.hotelWebsite {
  color: #0a332b !important;
  margin-bottom: 2rem;

  font-weight: bold;
}

.hotelDeets {
  margin-top: 2rem;
}

.hotelTransport {
  margin-top: 3rem;
}
.committeeBg {
  background-color: #0a332b;
  padding-bottom: 15%;
}
.committee {
  padding-top: 2rem;
}
.committeeTitles {
  text-align: center;
  margin: 2rem auto;
  color: #fff;
  font-weight: 900;
}
.registrationContainer {
  margin-bottom: 12%;
}
.registrationTitle {
  text-align: center;
  color: #0a332b;
}
.registrationTable {
  opacity: 0.8;
  font-weight: 900;
}
.registrationBtn {
  text-align: center;
  justify-content: center;
  margin-top: 5rem;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: none;
  padding: 20px;
  color: #fff;
  background-color: #0a332b;
}

.agenda {
  padding-top: 6rem;
  padding-bottom: 8rem;
  background: #d8ddde;
}

.agendaTitle {
  text-align: center;
  color: #0a332b;
  margin-bottom: 150;
}
.agendaBg {
  margin: auto;
  background-color: #2c3e50;
  border-radius: 9px;
  font-weight: 900;
  color: #fff;
}
.agendaHr {
  width: 82%;
  align-items: center;
  margin: -17px auto;
}

.footerDiv {
  background-color: #2c3e50;
  text-align: center;
  color: ghostwhite;
  padding: 2rem;
}
.footerBanner {
  height: 0.8rem;
}

hr.starLight {
  border-color: #fff;
}

.agendaBtn {
  background: transparent;
  color: #fff;
  font-size: 25px;
  outline: 'none';
  border: 3px solid #fff;
  margin-top: 20;
}
.agendaBtn:hover {
  color: #0a332b;
  background: #fff;
  border: 3px solid #fff;
}
.registerbtn {
  background: #ffff00b0;
  font-size: 25px;
  outline: 'none';
  border: 3px solid #fff;
  margin-top: 20;
  margin-left: 3rem;
}

.registerbtn:hover {
  background: #fff;
  border: 3px solid #fff;
}
.regbtn {
  text-decoration: 'none';
  color: '#fff';
}

.regbtn:hover {
  color: #0a332b;
}

.venueSection {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('./img/rutgers-university-inn.jpeg');
  background-position: center top;
  color: white;

  height: 55rem;
  overflow: hidden;
  color: #fff;
  background-size: cover;
}
.atagbtn:hover {
  color: #0a332b;
  /* color: #0a332b; */
  /* border: 3px solid #fff; */
}
